import { useState, useEffect, Fragment, useRef } from "react";
import { ifIos } from "./utils/ifIos";
import { getUrlParam } from "./utils/utils";
import './App.css';
import { androidDownload, iosDownload } from "./DownloadEvent";


function App() {
  const [showModal, setShowModal] = useState(false);
  let navH = 44;
  let param;
  if (ifIos()) {
    param = window.location.search;
  }
  if (param) {
    navH = parseInt(getUrlParam(param, "statusH")) + 44;
  }
  //判断是不是微信公众号下载
  const isWeChatOfficialAccount = () => {
    return /MicroMessenger/i.test(navigator.userAgent);
  };
  const handleAndroidClick = () => {
    if (isWeChatOfficialAccount()) {
      setShowModal(true);
    } else {
      androidDownload();
    }
  };

  const handleIOSClick = () => {
    if (isWeChatOfficialAccount()) {
      setShowModal(true);
    } else {
      iosDownload();
    }
  };

  return (
    <div className="downloadBox">
      <div style={{ paddingTop: `${(navH / 375) * 100}` }}></div>
      <div className="logo">
      <div className="logoImg"></div>
        {/* <h3>千遇直播</h3> */}
        <div className="logo-tit"></div>
      </div>

      <button className="android" onClick={handleAndroidClick}></button>
      <button className="ios" onClick={handleIOSClick}></button>
      {
        showModal && (
          <div className="browser">
            <div style={{ paddingTop: `${(navH / 375) * 100}` }}></div>
            <div className="line"></div>
            <div className="contentTitle">
              请点击右上角<div className="icon1"></div> 使用浏览器下载
              <div className="icon2"></div>
            </div>
          </div>
        )
      }
    </div>
  );
}

export default App;
