function iosDownload() {
    window.location.href = "itms-services:///?action=download-manifest&url=https://ml3.yixyy.cn/mile2.plist";
}
function androidDownload() {
    window.location.href = "https://tcos.yixyy.cn/app/qianyu.apk"
}

export {
    iosDownload,
    androidDownload
}
